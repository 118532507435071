<template>
  <div>
    <Divider dashed><span class="divider-text">开票汇总</span></Divider>
    <Row class="p-b-10">
      <i-col span="8" class="title">开票总额</i-col>
      <i-col span="16" class="money">
        {{ formatMoney(contractSummary.totalInvoicedAmount ? contractSummary.totalInvoicedAmount : 0) }}
      </i-col>
    </Row>
    <Row class="p-b-10">
      <i-col span="8" class="title">普票总额</i-col>
      <i-col span="16" class="money">
        {{ formatMoney(contractSummary.normalInvoicedAmount ? contractSummary.normalInvoicedAmount : 0) }}
      </i-col>
    </Row>
    <Row class="p-b-10">
      <i-col span="8" class="title">专票总额</i-col>
      <i-col span="16" class="money">
        {{ formatMoney(contractSummary.specialInvoicedAmount ? contractSummary.specialInvoicedAmount : 0) }}
      </i-col>
    </Row>
    <Divider dashed><span class="divider-text">快捷导入明细</span></Divider>
    <Row>
      <i-col span="24">
        <i-button
          type="success"
          size="small"
          class="m-r-5"
          @click="handleExportExcel"
          >导出模板</i-button
        >
        <Upload
          :action="action"
          :headers="uploadHeaders"
          :show-upload-list="false"
          :format="format"
          :on-format-error="handleFormatError"
          :on-success="finishimport"
          style="width: 70px; float: left"
        >
          <i-button type="success" size="small" class="m-r-5"
            >导入数据</i-button
          >
        </Upload>
      </i-col>
    </Row>
    <Table stripe :data="datalist" :columns="tableColumns"></Table>
    <div class="paging_style">
      <Page
        size="small"
        :total="total"
        :page-size="query.pageSize"
        show-total
        show-elevator
        :current="query.pageNumber"
        @on-change="handlePageChanged"
      ></Page>
    </div>
    <Modal v-model="showHistoryModal" width="800">
      <div class="workplatform-title">数据导入详情</div>
      <Row>
        <i-col span="24"
          ><span class="title">导入时间 </span
          >{{ currentInfo.submitTime }}</i-col
        >
         <i-col span="8"
          ><span class="title">导入人员 </span
          >{{ currentInfo.submitUserName }}</i-col
        >
          <i-col span="8"
          ><span class="title">状态 </span
          >{{ currentInfo.statusName }}</i-col
        >

        <i-col span="8" ></i-col
        >
         <i-col span="8"
          ><span class="title">导入总量 </span>{{ currentInfo.totalCount }}</i-col
        >
        <i-col span="8"
          ><span class="title">成功数量 </span>{{ currentInfo.successCount }}</i-col
        >
        <i-col span="8"
          ><span class="title">失败数量 </span>{{ currentInfo.failCount }}</i-col
        >
      </Row>

      <div slot="footer">
        <Button type="text" @click="showHistoryModal = false"
          >关闭</Button
        >
        <Button type="primary" @click="handleExportHistory">导出详情</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
import { downloadFileRequest } from '@/utils/download'
import { getStorage } from '@/utils/storage'
import { getinvoiceimportspage } from '@/api/scp/invoice'
export default {
  data () {
    return {
      action:
        process.env.VUE_APP_API_URL_V2 +
        '/ooh-scp/v1/invoiceimports/importsinvoicefromexcel',
      format: ['xls', 'xlsx'],
      uploadHeaders: {
        'ooh-auth': getStorage('ooh-token')
      },
      total: 0,
      query: {
        pageSize: 10,
        pageNumber: 1
      },
      showHistoryModal: false,
      currentInfo: {},
      datalist: [],
      tableColumns: [
        {
          title: '导入时间',
          key: 'submitTime'
        },
        {
          title: '导入数/成功数',
          key: 'totalCount',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                params.row.totalCount + ' / ' + params.row.successCount
              )
            ])
          }
        },
        {
          title: '状态',
          key: 'amount',
          render: (h, params) => {
            return h('div', [h('span', params.row.statusName)])
          }
        },
        {
          title: '操作',
          key: 'action',
          align: 'center',
          render: (h, params) => {
            const that = this
            return h('div', [
              h(
                'a',
                {
                  on: {
                    click: () => {
                      that.currentInfo = params.row
                      that.showHistoryModal = true
                    }
                  }
                },
                '详情'
              )
            ])
          }
        }
      ]
    }
  },
  created () {
    this.initImprotHistory()
  },
  methods: {
    formatMoney (value) {
      return toMoney(value)
    },
    // 导出模板
    handleExportExcel () {
      this.$Modal.confirm({
        title: '导出确认？',
        content: '您将要导出发票明细导入模板，是否继续？',
        loading: true,
        onOk: () => {
          downloadFileRequest(
            process.env.VUE_APP_API_URL_V2 +
              '/ooh-scp/v1/invoiceimports/downloadinvoiceimportstemplate',
            {},
            '发票明细导入模板.xlsx'
          )
          this.$Modal.remove()
        }
      })
    },
    handleFormatError (file) {
      this.$Notice.warning({
        title: '不支持的类型',
        desc: '请选择正确的文件进行导入！'
      })
    },

    // 完成导入
    finishimport () {
      this.$Notice.success({ desc: '数据文件上传成功，已提交处理，请稍后查看处理结果！' })
      this.initImprotHistory()
    },
    handlePageChanged (page) {
      this.query.pageNumber = page
      this.initImprotHistory()
    },
    initImprotHistory () {
      getinvoiceimportspage(this.query).then((res) => {
        this.datalist = res.list
        this.total = res.totalRow
      })
    },
    handleExportHistory () {
      this.$Modal.confirm({
        title: '导出确认？',
        content: '您将要导出' + this.currentInfo.submitTime + '上传的数据文档的导入结果信息，是否继续？',
        loading: true,
        onOk: () => {
          downloadFileRequest(
            process.env.VUE_APP_API_URL_V2 +
              '/ooh-scp/v1/invoiceimports/downloadinvoiceimportsfile',
            { invoiceimportsId: this.currentInfo.id, type: 1 },
            this.currentInfo.submitTime + '发票数据导入结果反馈.xlsx'
          )
          this.showHistoryModal = false
          this.$Modal.remove()
        }
      })
    }
  },
  computed: {
    contractSummary () {
      return this.$store.state.payment
        ? this.$store.state.payment.contractSummary
        : null
    }
  }
}
</script>
